import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "elements";
import moment from "moment";

import {
  Wrapper,
  Modal,
  Header,
  Heading,
  Title,
  Subtitle,
  CloseBtn,
  Body,
  Footer,
  Background,
} from "./styled";

// @ts-ignore
import { ReactComponent as CloseIcon } from "assets/images/priorities/close.svg";

// @ts-ignore
import { ReactComponent as FocusedIcon } from "./icon.svg";

import ActivityItem from "./components/ActivityItem";
import RequiredHours from "./components/RequiredHours";
import List from "./components/List";

import {
  listFocusedExtremes,
  closePopup,
} from "Redux/APM/ListFocusedExtremes/action";

// @ts-ignore
import { getPrioritizedActionData } from "Redux/APM/PrioritizedActions/action";

// @ts-ignore
import { removeFocusedPriorities } from "Redux/APM/ListFocusedPriorities/action";

// @ts-ignore
import { addPrioritizedActivityToFocused } from "Redux/APM/PrioritizedtoFocused/action";
import {
  IPrioritizedActivity,
  ITask,
  ListFocusedExtremesState,
} from "Redux/APM/ListFocusedExtremes/type";
import { getTask } from "Redux/APM/ListTasks/action";

const PrioritizationPopup = () => {
  const dispatch = useDispatch();

  const [isRendered, setIsRendered] = useState(false);
  const [isActiveState, setIsActiveState] = useState(false);

  const [selectedAction, setSelectedAction] = useState(
    null as IPrioritizedActivity | null
  );

  const [selectedTask, setSelectedTask] = useState(
    null as ITask | null
  );

  const [activitiesToUpdate, setActivitiesToUpdate] = useState(
    [] as { id: string; activityId?: string; value: number; original: number; isDeleted?: boolean }[]
  );

  const [activityTime, setActivityTime] = useState(0);

  const { data, popup, loading } = useSelector(
    (state: any) => state.listFocusedExtremes as ListFocusedExtremesState
  );
  const { progress } = useSelector((state: any) => state.listFocusedPrioritise);

  const { completed, prioritized } = progress;

  useEffect(() => {
    if (selectedAction) {
      setActivityTime(selectedAction.estimated_weekly_duration);
    } else if (selectedTask) {
      setActivityTime(selectedTask.estimated_duration || 0);
    }
  }, [selectedAction, selectedTask]);

  const freeMins = activitiesToUpdate.reduce(
    (acc, { value, original, isDeleted }) => acc + (original - (isDeleted ? 0 : value)),
    0
  );

  const totalMins = 168 * 60;
  const availableMins = totalMins - prioritized;

  const requiredMins =
    activityTime -
    availableMins -
    freeMins/*  -
    (activitiesToDelete.length
      ? activitiesToDelete.reduce((acc, { time }) => acc + time, 0)
      : 0); */

  // Calculate initial lowPriorityTime
  let lowPriorityTime = data.least_priority.reduce((acc, item) => 
    acc + (item.prioritized_activity ? item.prioritized_activity.total_time : item.task ? item.task.estimated_duration : 0), 
    0
  );

  // Filter out low priority items
  activitiesToUpdate.forEach(activity => {
    const item = data.least_priority.find(lp => lp.id === activity.id);

    if (item) {
      lowPriorityTime -= (activity.original - (activity.isDeleted ? 0 : activity.value));
    }
  });

  lowPriorityTime = Math.max(0, lowPriorityTime);

  useEffect(() => {
    if (popup) {
      setIsRendered(true);
      setTimeout(() => setIsActiveState(true), 50); // Delay setting $active to true by 50ms

      if (popup.task) {
        dispatch(getTask(popup.task, (data: ITask) => {
          setSelectedTask(data);
        }));
      }
      else {
        dispatch(
          getPrioritizedActionData(
            popup.prioritized_activity,
            (data: IPrioritizedActivity) => {
              setSelectedAction(data);
            }
          )
        );
      }

      dispatch(listFocusedExtremes());

      setTimeout(() => {
        if ($input && $input.current) {
          // @ts-ignore
          $input.current.focus();
        }
      }, 50);
    } else {
      setSelectedAction(null);
      setSelectedTask(null);
      setActivitiesToUpdate([]);

      setIsActiveState(false);
      setTimeout(() => setIsRendered(false), 500); // Delay unmounting by 500ms
    }
  }, [popup]);

  const $input = useRef(null);

  const handleClose = () => {
    dispatch(closePopup());
  };

  const handleActivityTimeChange = (_: string, __: string, value: number) => {
    setActivityTime(value);
  };

  const handleTimeChange = (id: string, activityId: string, value: number, original: number) => {
    setActivitiesToUpdate((prev) => {
      const exists = prev.some((activity) => activity.id === id);

      if (exists) {
        return prev.map((activity) =>
          activity.id === id ? { ...activity, value, original } : activity
        );
      } else {
        return [...prev, { id, activityId, value, original }];
      }
    });
  };

  const handleRemove = (id: string, original: number) => {
    setActivitiesToUpdate((prev) => {
      const exists = prev.some((activity) => activity.id === id);

      if (exists) {
        return prev.map((activity) =>
          activity.id === id ? { ...activity, isDeleted: !activity.isDeleted } : activity
        );
      } else {
        return [...prev, { id, value: 0, original, isDeleted: true }];
      }
    });
  };

  const handleSubmit = () => {
    processSubmit(false);
  };

  const processSubmit = (nextWeek: boolean) => {
    if (!popup) return;

    const activitiesToDelete = activitiesToUpdate.filter((activity) => activity.isDeleted);

    if (activitiesToDelete.length === 0) {
      addActivitiesToFocused(nextWeek);
      return;
    }

    dispatch(
      removeFocusedPriorities(
        activitiesToDelete.map((item) => item.id),
        () => {
          addActivitiesToFocused(nextWeek);
        }
      )
    );
  };

  const addActivitiesToFocused = (nextWeek: boolean) => {
    if (!popup) return;

    const weekStarting = nextWeek
      ? moment(popup.week_starting).add(7, "days").format("YYYY-MM-DD")
      : popup.week_starting;

    dispatch(
      addPrioritizedActivityToFocused({
        prioritized_activity: popup.prioritized_activity,
        task: popup.task,
        user: popup.user,
        override_limit: true,
        week_starting: weekStarting,
        estimated_duration: activityTime,
        focused_updates: activitiesToUpdate.filter(item => !item.isDeleted).map(({ activityId, value }) => ({
          id: activityId,
          estimated_duration: value,
        })),
      })
    );
  };

  const handlePostpone = () => {
    processSubmit(true);
  };

  if (!isRendered) {
    return null;
  }

  return createPortal(
    <Wrapper $active={isActiveState}>
      <Modal $active={isActiveState}>
        <Header>
          <CloseBtn onClick={handleClose}>
            <CloseIcon />
          </CloseBtn>

          <Heading>
            <FocusedIcon />
            <Title>You’ve scheduled over the maximum hours this week</Title>
            <Subtitle>
              This activity puts you over 168 weekly hours. Please adjust your
              activity estimates or schedule activities for a different week.
            </Subtitle>
          </Heading>
        </Header>

        <Body>
          {(selectedAction || selectedTask) && (
            <ActivityItem
              activity={selectedAction}
              task={selectedTask}
              priority={1}
              showGoal={!!selectedAction}
              showDetails
              onTimeChange={handleActivityTimeChange}
              requiredMins={requiredMins}
            />
          )}

          <RequiredHours value={requiredMins} />

          <List
            data={data}
            lowPriorityTime={lowPriorityTime}
            loading={loading}
            onTimeChange={handleTimeChange}
            onRemove={handleRemove}
          />
        </Body>

        <Footer>
          <Button styleType="secondary" onClick={handlePostpone}>
            Add to Next Week
          </Button>
          <Button disabled={requiredMins > 0} onClick={handleSubmit}>
            Add Activity
          </Button>
        </Footer>
      </Modal>
      <Background />
    </Wrapper>,
    document.getElementById("portal") as HTMLElement
  );
};

export default PrioritizationPopup;
