import { BaseService } from "api/base";

const buildUrl = (url, params) => {
    const queryString = Object.keys(params)
        .filter(key => params[key] !== '' && params[key] !== undefined && params[key] !== null)
        .map(key => {
            const value = params[key];
            if (Array.isArray(value)) {
                // Join array values with a comma
                return `${encodeURIComponent(key)}=${encodeURIComponent(value.join(','))}`;
            } else {
                // Directly encode the string representation of the value
                return `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`;
            }
        })
        .join('&');
    return url + (queryString ? `?${queryString}` : '');
};

export const listUnprioritizedActions = () => BaseService.get(`/apm/prioritized-activities/list_unprioritized_actions/`);

export const listPrioritizedActivities = (filterParams = {}) => {
    const url = buildUrl('/apm/prioritized-activities/', filterParams);
    return BaseService.get(url);
}

export const listPrioritizedActivitiesGrouped = (filterParams = {}) => {
    const url = buildUrl('/apm/prioritized-activities/grouped_priorities/', filterParams);
    return BaseService.get(url);
}

export const listFocusedPrioritized = (filterParams = {}) => {
    const url = buildUrl('/apm/focused-priorities/', filterParams);
    return BaseService.get(url);
}

export const listCompleteFocusedPrioritized = (filterParams = {}) => {
    const url = buildUrl('/apm/focused-priorities/completed_focused_priorities/', filterParams);
    return BaseService.get(url);
}

export const createPrioritizedActivity = (data) => BaseService.post(`/apm/prioritized-activities/`, data);

export const listAvailableAreasAndIndirectScores = (filterParams = {}) => {
    const url = buildUrl('/apm/prioritized-activities/list_available_areas/', filterParams);
    return BaseService.get(url);
}

export const updateIndirectScores = (data) => BaseService.post(`/apm/prioritized-activities/update_indirect_scores/`, data);
export const updateTimeline = (data) => BaseService.post(`/apm/prioritized-activities/update_timeline/`, data);
export const updateWeeklyDuration = (data) => BaseService.post(`/apm/prioritized-activities/update_weekly_duration/`, data);
export const addPrioritizedActivityToFocusedActivities = (data) => BaseService.post(`/apm/focused-priorities/${data.override_limit ? '?override_limit=True' : ''}`, data);
export const updateFocusedActivityPriorities = (data) => BaseService.post(`/apm/focused-priorities/update_priorities/`, data);
export const removeFocusedPriority = (focusedPriorityId) => BaseService.remove(`/apm/focused-priorities/${focusedPriorityId}/`);
export const removeFocusedPriorities = (focusedPrioritiesIds) => BaseService.post(`/apm/focused-priorities/bulk-delete/`, focusedPrioritiesIds);

export const listJourneyFilters = (data) => {
    const url = buildUrl('/apm/prioritized-activities/my_journeys/', data);
    return BaseService.get(url);
}
export const listAreaFilters = () => BaseService.get(`/apm/prioritized-activities/my_areas/`);

export const listCategories = (data) => {
    const url = buildUrl('/domains/search_categories/', data);
    return BaseService.get(url);
}

export const listCustomCategories = (data) => {
    const url = buildUrl('/domains/search_custom_categories/', data);
    return BaseService.get(url);
}

export const listGoals = (data) => {
    const url = buildUrl('/apm/prioritized-activities/list_goals/', data);
    return BaseService.get(url);
}

export const listTasks = (data) => {
    const url = buildUrl('/tasks/', data);
    return BaseService.get(url);
}

export const createActionItem = (data) => BaseService.post(`/apm/prioritized-activities/create_action/`, data);
export const updateActionItem = (data) => BaseService.post(`/apm/prioritized-activities/update_action/`, data);
export const createTask = (data) => BaseService.post(`/tasks/`, data);
export const updateTask = (data, id) => BaseService.patch(`/tasks/${id}/`, data);
export const deleteTask = (id) => BaseService.remove(`/tasks/${id}/`);
export const completeTask = (task_id) => BaseService.post(`/tasks/mark_completed/`, {task_id:task_id});

export const getFocusedExtremes = (filterParams = {}) => {
    const url = buildUrl('/apm/focused-priorities/time_extremes/', filterParams);
    return BaseService.get(url);
};
