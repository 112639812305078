import { useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { Button, InputField } from "elements";
import { Dropdown } from "antd";

import InputAreaAI from "components/InputAreaAI";

import { ReactComponent as ChevronRightIcon } from "assets/images/journeys/chevron-right.svg";
import { ReactComponent as PlusSmIcon } from "assets/images/journeys/plus-sm.svg";
import { ReactComponent as GearIcon } from "assets/images/journeys/gear.svg";

import { Wrapper, Body, Footer, FooterCol, Col, Text, DropdownToggle } from './styled';

import styles from './styles.module.scss';

function flattenList(list) {
  return list.reduce((acc, item) => {
    if (item.children && item.children.length > 0) {
      acc = acc.concat(item.children);
    }

    return acc;
  }, []);
}

const UnitsSelector = ({ id, predefinedData, customData, goalData, assistant, onClick, onChange, onBulkChange, onOpen, onClose }) => {
  const { loading } = useSelector(state => state.updateGoalReducer);

  const $dropdownToggle = useRef(null);
  const $inputStart = useRef(null);
  const $inputCurrent = useRef(null);
  const $inputTarget = useRef(null);

  const [isLoading, setIsLoading] = useState(false);

  const [settings, setSettings] = useState({
    unit: '',
    current: '',
    start: '',
    target: ''
  });

  useEffect(() => {
    if ($dropdownToggle && $dropdownToggle.current && !goalData.measurement_metric_name)
      $dropdownToggle.current.focus();
    else if ($inputStart && $inputStart.current)
      $inputStart.current.focus();
  }, [id, goalData.measurement_metric_name]);

  useEffect(() => {
    setIsLoading(loading);

    if (!loading) {
      setTimeout(() => {
        if (!settings.start.length)
          $inputStart.current?.focus();
        else if (!settings.current.length)
          $inputCurrent.current?.focus();
        else if (!settings.target.length)
          $inputTarget.current?.focus();
       
      }, 50);
    }
  }, [loading]);

  useEffect(() => {
    setSettings(prev => ({
      ...prev,
      unit: goalData.measurement_metric_name || '',
      current: goalData.measurement_current_value?.toString() || '',
      start: goalData.measurement_start_value?.toString() || '',
      target: goalData.measurement_target_value?.toString() || ''
    }));

    if (!(goalData.measurement_start_value?.toString() || '').length)
      $inputStart.current?.focus();
    else if (!(goalData.measurement_current_value?.toString() || '').length)
      $inputCurrent.current?.focus();
    else if (!(goalData.measurement_target_value?.toString() || '').length)
      $inputTarget.current?.focus();
  }, [id, goalData]);

  const listOfUnits = [
    ...predefinedData.map(item => ({
      key: item.id,
      children: item.units.map(unit => ({ key: unit.id, label: unit.name })),
      label: item.label
    })),
    ...customData.length ? [{
      label: "Custom Metrics",
      type: 'group'
    }] : [],
    ...customData.map(item => ({
      key: item.id,
      children: item.units.map(unit => ({ key: unit.id, label: unit.name })),
      label: item.label
    })),
    customData.length
      ? {
        key: 'manage',
        label: (
          <>
            Manage Metrics
            <GearIcon />
          </>
        )
      }
      : {
        key: 'custom',
        label: (
          <>
            Add Custom
            <PlusSmIcon />
          </>
        )
      }
  ];

  const flattenListOfUnits = flattenList(listOfUnits);
  const unitLabel = flattenListOfUnits.find(item => item.key === settings.unit)?.label;

  const onDropdownChange = e => {
    if (e.key === 'manage' || e.key === 'custom') {
      onClick(e);
      return;
    }

    setSettings(prev => ({
      ...prev,
      unit: e.key,
      current: '',
      start: '',
      target: ''
    }));

    $inputStart.current.focus();
  }

  const handleChange = field => e => {
    setSettings(prev => ({
      ...prev,
      [field]: e.target.value
    }));
  }

  const handleApply = () => {
    setIsLoading(true);

    const shouldClose = settings.unit.length > 0 && settings.current.length > 0 && settings.start.length > 0 && settings.target.length > 0;

    onBulkChange({
      measurement_metric_name: unitLabel || settings.unit || null,
      measurement_current_value: settings.current.length ? settings.current : null,
      measurement_start_value: settings.start.length ? settings.start : null,
      measurement_target_value: settings.target.length ? settings.target : null
    }, shouldClose);
  }

  const handleKeyPress = () => e => {
    if (e.key === "Enter") {
      handleApply();
    }
  }

  const handleOpenChange = status => {
    onOpen(status);
  }

  const keyOfMatchingElement = Object.values(listOfUnits.filter(item => item.children)).reduce((acc, unit) => {
    if (acc) {
      return acc;
    }

    const foundChild = unit.children.find(child => child.label === goalData?.measurement_metric_name);
    return foundChild ? foundChild.key : null;
  }, null);

  const hasNewChanges
    = settings.unit !== goalData.measurement_metric_name
    || settings.current !== goalData.measurement_current_value?.toString()
    || settings.start !== goalData.measurement_start_value?.toString()
    || settings.target !== goalData.measurement_target_value?.toString();

  return (
    <Wrapper>
      <Body>
        <Col>
          <Text>Metric</Text>
          <Dropdown
            menu={{ items: listOfUnits, selectedKeys: [keyOfMatchingElement], onClick: onDropdownChange/* , openKeys: ["4972dd78-6104-4656-9fe9-44e98b82c827"] */ }}
            trigger={['click']}
            disabled={isLoading}
            overlayClassName={styles.dropdown}
            onOpenChange={handleOpenChange}
          >
            <DropdownToggle title={(unitLabel || settings.unit) || 'Select'} disabled={isLoading} ref={$dropdownToggle}>
              {(unitLabel || settings.unit) ? <span>{(unitLabel || settings.unit)}</span> : <span className="default">Select</span>}
              <ChevronRightIcon />
            </DropdownToggle>
          </Dropdown>
        </Col>
        <Col $flex={1}>
          <Text>Start</Text>
          <InputField
            placeholder="-"
            value={settings.start}
            disabled={!settings.unit || isLoading}
            onChange={handleChange('start')}
            onKeyPress={handleKeyPress('start')}
            ref={$inputStart}
          />
        </Col>
        <Col $flex={1}>
          <Text>Current</Text>
          <InputField
            placeholder="-"
            value={settings.current}
            disabled={!settings.unit || isLoading}
            onChange={handleChange('current')} 
            onKeyPress={handleKeyPress('current')}
            ref={$inputCurrent}
          />
        </Col>
        <Col $flex={1}>
          <Text>Target</Text>
          <InputField
            placeholder="-"
            value={settings.target}
            disabled={!settings.unit || isLoading}
            onChange={handleChange('target')}
            onKeyPress={handleKeyPress('target')}
            ref={$inputTarget}
          />
        </Col>
      </Body>

      <Footer>
        <FooterCol>
          {assistant ? <InputAreaAI
            assistant={assistant}
            onlyButton
            onOpen={onOpen}
            disabled={isLoading}
            readOnly
          /> : null}
        </FooterCol>

        <FooterCol>
          <Button styleType="link-primary" size="small" disabled={isLoading} onClick={onClose}>Cancel</Button>
          <Button size="small" disabled={isLoading || !(unitLabel || settings.unit) || !hasNewChanges} onClick={handleApply}>{isLoading ? 'Saving...' : 'Apply'}</Button>
        </FooterCol>
      </Footer>
    </Wrapper>
  );
}

export default UnitsSelector;
