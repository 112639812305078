import PropTypes from "prop-types";

import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Dropdown, Button, Tooltip } from "elements";

import sidebarCloseIcon from "assets/images/journeys/sidebar-close.svg";

import { ReactComponent as ChevronRightIcon } from "assets/images/journeys/chevron-right.svg";

import { ToggleSidebarAction } from "Redux/Global/action.js";

import * as Styles from "./styled.js";
import {
  ACTIONS_PATH,
  GOALS_PATH,
  MASTER_PATH,
  MEASUREMENT_PATH,
  MISSION_PATH,
  JOURNEY_PATH,
  PRIORITIZE_PATH,
  PURPOSE_PATH,
} from "utils/routes";
import { forwardRef, useImperativeHandle, useRef } from "react";

const stepsItems = [
  {
    key: "0",
    element: "1. Area/Category",
  },
  {
    key: "1",
    element: "2. Prioritization",
  },
  /* {
    key: "2",
    element: "3. Goal Statement",
  }, */
  {
    key: "3",
    element: "3-4. Goal Statement & Measurement",
  },
  {
    key: "4",
    element: "5. Purpose",
  },
  {
    key: "5",
    element: "6-10. Goal Action Plan",
  },
];

function getStepPath(step) {
  switch (step) {
    case "0":
      return MISSION_PATH;
    case "1":
      return PRIORITIZE_PATH;
    case "2":
      // return GOALS_PATH;
    case "3":
      return MEASUREMENT_PATH;
    case "4":
      return PURPOSE_PATH;
    case "5":
    case "6":
    case "7":
    case "8":
    case "9":
      return ACTIONS_PATH;
    case "10":
      return MASTER_PATH;
  }

  return MISSION_PATH;
}

function getStepCount(path) {
  switch (path) {
    case MISSION_PATH:
      return "0";
    case PRIORITIZE_PATH:
      return "1";
    case GOALS_PATH:
      return "3"; // 2
    case MEASUREMENT_PATH:
      return "3";
    case PURPOSE_PATH:
      return "4";
    case ACTIONS_PATH:
      return "5";
    case MASTER_PATH:
      return "10";
  }

  return "0";
}

const StepLayout = forwardRef(({
  title,
  header,
  footerLeft,
  smallerFooter,
  progress,
  pagination,
  nextPageTitle = "Continue",
  nextPageTooltip,
  isPrevDisabled,
  isNextDisabled,
  onPrev,
  onNext,
  onNavigate,
  children,
}, ref) => {
  const $containerRef = useRef(null);

  useImperativeHandle(ref, () => ({
    enableScroll: () => {
      if ($containerRef.current) {
        $containerRef.current.style.overflow = 'auto';
        $containerRef.current.style.paddingRight = '0px';
      }
    },
    disableScroll: () => {
      if ($containerRef.current) {
        $containerRef.current.style.overflow = 'hidden';
        $containerRef.current.style.paddingRight = '26px';
      }
    },
    scrollToTop: () => {
      if ($containerRef.current) {
        $containerRef.current.scrollTop = 0;
      }
    }
  }));

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { toggledSidebar } = useSelector((state) => state.globalReducer);

  const onTogglerClickHandle = () => {
    dispatch(ToggleSidebarAction(toggledSidebar === "" ? "left" : ""));
  };

  const handleStepChange = ({ key }) => {
    if (onNavigate)
      onNavigate(getStepPath(key));
    else
      navigate(getStepPath(key))
  };

  const stepPathValue = getStepCount(location.pathname);

  return (
    <Styles.Container>
      <Styles.Step $header>
        <Styles.Heading>
          <Styles.Toggler
            $active={toggledSidebar === ""}
            type="button"
            onClick={onTogglerClickHandle}
          >
            <img src={sidebarCloseIcon} alt="" />
          </Styles.Toggler>

          <Styles.Title $active={toggledSidebar === ""}>{title}</Styles.Title>
        </Styles.Heading>

        <Styles.ButtonWrapper>
          {progress}

          <Dropdown
            menuItems={stepsItems}
            value={stepPathValue}
            secondary
            onChange={handleStepChange}
          >
            <Styles.StepsToggler>
              All Steps
              <ChevronRightIcon />
            </Styles.StepsToggler>
          </Dropdown>
        </Styles.ButtonWrapper>
      </Styles.Step>

      {header && <Styles.SecondaryHeader>{header}</Styles.SecondaryHeader>}

      <Styles.ContainerItem $smaller={smallerFooter} $withHeader={!!header} $pagination={!!pagination} ref={$containerRef}>
        {children}
      </Styles.ContainerItem>

      <Styles.Step $footer $pagination={!!pagination}>
        <Styles.FooterLeft>
          {footerLeft ? (
            footerLeft
          ) : (
            <Styles.PrimaryLinkJourney to={JOURNEY_PATH}>
              All Journeys{" "}
            </Styles.PrimaryLinkJourney>
          )}
        </Styles.FooterLeft>

        <Styles.FooterMiddle>{pagination}</Styles.FooterMiddle>

        <Styles.NextButtonWrapper>
          {onPrev && (
            <Button
              styleType="secondary"
              disabled={isPrevDisabled}
              onClick={onPrev}
            >
              Back
            </Button>
          )}

          {onNext && (
            nextPageTooltip ? (
              <Tooltip placement="topRight" type="secondary" title={nextPageTooltip} alternative>
                <Button
                  styleType="primary"
                  disabled={isNextDisabled}
                  onClick={onNext}
                >
                  {nextPageTitle}
                </Button>
              </Tooltip>
            ) : (
              <Button
                styleType="primary"
                disabled={isNextDisabled}
                onClick={onNext}
              >
                {nextPageTitle}
              </Button>
            )
          )}
        </Styles.NextButtonWrapper>
      </Styles.Step>
    </Styles.Container>
  );
});

StepLayout.propTypes = {
  title: PropTypes.string.isRequired,
  footerLeft: PropTypes.node,
  smallerFooter: PropTypes.bool,
  progress: PropTypes.node,
  pagination: PropTypes.node,
  isPrevDisabled: PropTypes.bool,
  isNextDisabled: PropTypes.bool,
  nextPageTitle: PropTypes.string,
  nextPageTooltip: PropTypes.string,
  onPrev: PropTypes.func,
  onNext: PropTypes.func,
  onNavigate: PropTypes.func,
  children: PropTypes.node.isRequired,
};

export default StepLayout;
