import { FC, useRef, useState } from 'react';
import { useSelector } from "react-redux";
import { InputArea } from "elements";

// @ts-ignore
import PopoverCustom from "components/PriorityApp/SimpleView/PopoverCustom/index";

// @ts-ignore
import UnitsSelector from "components/Journeys/Measurement/UnitsSelector/index.jsx";

// @ts-ignore
import * as Styles from './styled.js';

interface IHeader {
  data: any;
  readOnly: boolean;
  isPrivate?: boolean;
  onUpdate: (fields: { [key: string]: any }, id: string, callback?: () => void) => void;
  onAddMetricOpen: () => void;
  onAddMetricToggle: () => void;
}

const Header: FC<IHeader> = ({ data, readOnly, isPrivate, onUpdate, onAddMetricOpen, onAddMetricToggle }) => {
  const $inputRef = useRef<{
    focus: () => void;
    click: () => void;
  }>(null);

  const [isAssistantOpen, setIsAssistantOpen] = useState(false);

  // @ts-ignore
  const { data: predefinedMetrics } = useSelector((state) => state.predefinedMetricsReducer);

  // @ts-ignore
  const { data: customMetrics } = useSelector((state) => state.customMetricsReducer);

  const onUnitsChangeHandle = (id: string) => (e: any) => {
    if (e.key === "custom")
      onAddMetricOpen();
    else if (e.key === "manage")
      onAddMetricToggle();
  };

  const handleMeasurementsChange = (id: string) => (field: string, value: string) => {
    onUpdate({ [field]: value }, id);
  };

  const handleMeasurementsBulkChange = (id: string) => (values: any, close: boolean) => {
    onUpdate(values, id, () => {
      if (close) {
        $inputRef.current?.click();
      }
    });
  }

  const handleAssistantOpen = (value: boolean) => {
    setIsAssistantOpen(value);
  }

  const handleUnitsClose = () => {
    setIsAssistantOpen(false);
    $inputRef.current?.click();
  }

  const measurementValue = `${data?.goal?.measurement_current_value ||
    data?.goal?.measurement_target_value
    ? `${data?.goal?.measurement_current_value ??
    " - "
    }/${data?.goal?.measurement_target_value ??
    " - "
    }`
    : "-"} ${data?.goal?.measurement_metric_name}`;

  return (
    <Styles.Wrapper $color={data?.color}>
      <Styles.Row>
        <Styles.Col $justify="center" $width="44px" $ml="12px" $mr="40px">
          <Styles.Title>Priority</Styles.Title>
          <Styles.Circle>{data?.goal?.priority}</Styles.Circle>
        </Styles.Col>
        <Styles.Col $width={'15.13%'} $pr="12px">
          <Styles.Title>Area</Styles.Title>
          <Styles.Heading title={data?.name}>{data?.name}</Styles.Heading>
        </Styles.Col>
        <Styles.Col $width={'20.64%'} $pr="12px">
          <Styles.Title>Category</Styles.Title>
          <Styles.Heading title={data?.goal?.category_name}>{data?.goal?.category_name}</Styles.Heading>
        </Styles.Col>
        <Styles.Col>
          <Styles.Title>Measurement</Styles.Title>
          {(readOnly || isPrivate) ? null : <PopoverCustom
            keepOpen={isAssistantOpen}
            popoverStyle={{
              containerStyle: {
                zIndex: "998"
              }
            }}
            content={
              <UnitsSelector
                key={data?.id}
                id={data?.id}
                predefinedData={predefinedMetrics}
                customData={customMetrics}
                goalData={data?.goal}
                onClick={onUnitsChangeHandle(data?.id)}
                onChange={handleMeasurementsChange(data?.id)}
                onBulkChange={handleMeasurementsBulkChange(data?.id)}
                onOpen={handleAssistantOpen}
                onClose={handleUnitsClose}
              />
            }
          >
            <Styles.PopoverToggle>
              <InputArea
                style={{
                  minWidth: 106,
                  textAlign: 'left',
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                // @ts-ignore
                title={measurementValue}
                // @ts-ignore
                suffix={data?.goal?.measurement_metric_name}
                max={300}
                value={measurementValue}
                readOnly
                ref={$inputRef}
              />
            </Styles.PopoverToggle>
          </PopoverCustom>}
          {readOnly ? <Styles.Heading>{measurementValue} <span>{data?.goal?.measurement_metric_name}</span></Styles.Heading> : null}
          {isPrivate ? <Styles.Heading>--Private--</Styles.Heading> : null}
        </Styles.Col>
      </Styles.Row>

      <Styles.Row $secondary>
        <Styles.Col $secondary $justify="flex-start" $align="center" $width="91" $ml="7px" $mr="22px">
          <Styles.Title $secondary>Purpose</Styles.Title>
        </Styles.Col>
        <Styles.Col $secondary $justify="flex-start" $width={'calc(38.75% - 56px)'} $pr="22px">
          <Styles.Title>Rewards</Styles.Title>
          <Styles.Text>{data?.goal?.pleasure || ' — '}</Styles.Text>
        </Styles.Col>
        <Styles.Col $secondary $justify="flex-start" $width={'38.75%'} $pr="36px">
          <Styles.Title>Consequences</Styles.Title>
          <Styles.Text>{data?.goal?.pain || ' — '}</Styles.Text>
        </Styles.Col>
      </Styles.Row>
    </Styles.Wrapper>
  );
}

export default Header;
