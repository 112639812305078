import LeveragePopover from "../../LeveragePopover";
import PopoverCustom from "../../PopoverCustom";
//@ts-ignore  


import { ReactComponent as Pencil } from "assets/images/priorities/pencil.svg";
//@ts-ignore  

import { ReactComponent as QuestionMarkIcon } from "assets/images/priorities/question-mark.svg";

import { Wrapper, AreaBox, AreaHeader, RightBar, DirectImpactStyle, IndirectImpactStyle, LeverageButton, List } from './styled';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
//@ts-ignore  

import { availableAreas } from 'Redux/APM/AvailableAreas/action';
//@ts-ignore  

import { updateIndirectScore } from 'Redux/APM/UpdateIndirectScores/action';
import ImpactComponent from '../../LeveragePopover/ImpactBar';
import ImpactIndicator from '../../LeveragePopover/ImpactIndicator';
import DirectImpact from 'components/PriorityApp/DirectImpactPopover';
import InDirectImpact from 'components/PriorityApp/IndirectImpactPopover';
//@ts-ignore  
import { fetchListPrioritizedActions } from '../../../../../Redux/APM/PrioritizedActions/action';

interface Props {
  id: string;
  selectedItem: any;
}
interface Area {
  name?: string;
  color?: string;
  id?: string;
}
interface ImpactArea {
  user_area?: string;
  score?: string;
}
export interface AreaData {
  area: Area;
  impactArea: ImpactArea;
}
interface updateIndirectScores{
  loading:boolean
}
interface RootupdateIndirectScoresState{
  updateIndirectScores:updateIndirectScores
}

interface AvailableArea {
    areas: Area[];
    indirect_impact_area_scores: ImpactArea[];
}

interface AvailableAreaState {
  availablearea: AvailableArea;
}

interface RootAvailableAreaStateState {
  availableAreas: AvailableAreaState;
}

const Impact: React.FC<Props> = ({ id, selectedItem }) => {
  const [close, setClose] = useState<boolean>();
  const [refetch, setRefetch] = useState<boolean>(false);
  const [hoveredComponent, setHoveredComponent] = useState<string>('');
  const [popupId, setPopupId] = useState<string>();
  const { availablearea } = useSelector((state:RootAvailableAreaStateState) => state.availableAreas);
  const { loading } = useSelector((state:RootupdateIndirectScoresState) => state.updateIndirectScores);
  const { direct_impact, indirect_impact } = selectedItem || {};
  const [areaData, setAreaData] = useState<AreaData[]>([]); 
  
  const dispatch = useDispatch();

  useEffect(() => {
    if (id) {
      dispatch(availableAreas(id));
    }
    setPopupId(id);
  }, [refetch, loading]);

  const onLeverageClose = async (shouldUpdate?: boolean, values?: AreaData[], id?: string) => {
    setClose(!close);
  
    if (shouldUpdate && id) {
      const ad = Array.isArray(values) && values.length > 0 ? values : areaData;
      const impactAreaArray = ad
        .filter((item: AreaData) => item.impactArea.user_area && (item.impactArea.score !== '0.0' && item.impactArea.score !== '-'))
        .map((item: AreaData) => item.impactArea);

      const payload = {
        "updated_scores": {
          "prioritized_activity": id,
          "scores": impactAreaArray.map((item: ImpactArea) => ({ user_area: item.user_area, score: item.score ? item.score : 0}))
        }
      };
  
      await dispatch(updateIndirectScore(payload));
      await dispatch(fetchListPrioritizedActions());
      setAreaData([]);
    }
  };

  const directImpactColor: { color: string; }[] = (availablearea?.areas?.map((ele: Area) => {
    return { color: ele?.color ?? 'defaultColor' };
  }) ?? []) as { color: string; }[];

  /* const inDirectImpact = availablearea?.indirect_impact_area_scores?.reduce((sum: number, element: ImpactArea) => {
    const weightAsNumber = element?.score ? parseInt(element.score) : 0; 
    if (!isNaN(weightAsNumber)) {
      sum += weightAsNumber;
    }
    return sum;
  }, 0); */

  /* const handleMouseEnter = (componentName: string) => {
    setHoveredComponent(componentName);
  };

  const handleMouseLeave = () => {
    setHoveredComponent('');
  }; */

  return (
    <Wrapper>
      <AreaBox>
        <ImpactComponent
          color={directImpactColor}
          direct_impact={5}
          indirect_impact={parseInt(indirect_impact) === 0 ? 5 : indirect_impact}
        />

        <ImpactIndicator
          id={id}
          selectedItem={selectedItem}
          onLeverageClose={() => onLeverageClose()}
          color={directImpactColor}
          indirect_impacts_value={indirect_impact && parseFloat(indirect_impact)}
          direct_impact_value={direct_impact && parseFloat(direct_impact)}
          indirect_impacts={parseFloat(direct_impact) === 0 ? 5 : direct_impact}
          direct_impact={5}
          indirect_impacts_editable={
            <PopoverCustom 
              close={close}
              onClose={() => onLeverageClose()}
              content={
                <LeveragePopover
                  selectedItem={selectedItem}
                  onClose={() =>  setClose(!close)}
                  onModalClose={() => onLeverageClose(true, areaData, id)}
                  id={id}
                  setOutsideAreaData={setAreaData}
                />
              }
              positions={['top', 'bottom', 'left']}
              popoverStyle={{ arrowStyle: { bottom:"195px", left: "183px", zIndex: 99999, rotate: '180deg'}, containerStyle: { height:'200px', top: '222px', left: '25px' } }}
            >
              <LeverageButton>
                <Pencil onClick={() => setRefetch(!refetch)} />
              </LeverageButton>
            </PopoverCustom>
          }
        />
      </AreaBox>
    </Wrapper>
  );
}

export default Impact;
