import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { DragDropContext, Droppable, DropResult } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";

import {
  Wrapper,
  TargetSection,
  TargetTitle,
  TargetCalander,
  TargetCalanderSection,
  ProgressSection,
  ProgressActions,
  ProgressAction,
  Dot,
  ScheduleSection,
  ScheduleTitle,
  ScheduleButton,
  RelativeWrapper,
  HiddenBox,
  BackgroundSkeleton,
  FooterText,
  SectionDrag,
  CursorPointer,
  CompletedHeading,
  CloseIcon,
  ScheduleListContainer,
} from "./styled";

import ProgressBar from "./component/ProgressBar";
import { EmptyCard } from "./component/emptyCard";

// @ts-ignore
import { listFocusedPrioritise } from "Redux/APM/ListFocusedPriorities/action";
// @ts-ignore
import { listCompleteFocusedPrioritise } from "Redux/APM/ListFocusedPriorities/action";
// @ts-ignore
import { updateFocusedPriorities } from "Redux/APM/UpdateFocusedPriorities/action";
// @ts-ignore
import { ReactComponent as TargetButton } from "assets/images/priorities/TargetButton.svg";
// @ts-ignore
import { ReactComponent as QuestionIcn } from "assets/images/priorities/QuestionIcn.svg";
// @ts-ignore
import { ReactComponent as ScheduleGray } from "assets/images/priorities/scheduleGray.svg";
// @ts-ignore
// import { ReactComponent as Stars } from "assets/images/priorities/stars.svg";
// @ts-ignore
import { ReactComponent as ArrowLeft } from "assets/images/priorities/arrowLeft.svg";
// @ts-ignore
import { ReactComponent as ArrowRight } from "assets/images/priorities/arrowRight.svg";
// @ts-ignore
import { IoClose } from "react-icons/io5";

//@ts-ignore
import { RootState } from "Redux/store";
import List from "./component/List";
import PriortizedPopover from "components/PriorityApp/PriortizedPopover";
import ScheduleModel from "./component/scheduleModel";
import CustomWeekPicker from "./component/weekPicker";
import { Popover } from "antd";
import { websocketConnection } from "utils/apmWebsocket";

interface FocusedItem {
  id: string;
  focusedItems: FocusedItem[];
  numberOfSkeletons: number;
  onClose: () => void;
}

const FocusedPriorities: React.FC<{
  onFocusToggle: () => void;
  screenSizes: string;
  ipadView?: boolean;
  setIpadView?: any;
  setBackground?: any;
}> = ({ onFocusToggle, screenSizes, ipadView, setIpadView, setBackground }) => {
  const { id } = useParams();

  const { listFocusedPrioritise: originalFocusedItems } = useSelector(
    (state: RootState) => state.listFocusedPrioritise
  );

  const { listCompleteFocusedPrioritise: originalCompleteFocusedItems } =
    useSelector((state: RootState) => state.listFocusedPrioritise);

  const { week } = useSelector(
    (state: RootState) => state.listFocusedPrioritise
  );

  const dispatch = useDispatch();
  const wrapperRef = useRef<any>(null);
  const startXRef = useRef<any>(null);

  // @ts-ignore
  const { userDetail } = useSelector((state) => state.userDetailReducer);

  const goalSettingWorkbookKey = userDetail?.external_accounts.find(
    (account: any) => "goal_setting_workbook" in account
  );

  const [focusedItems, setFocusedItems] = useState<FocusedItem[]>([]);
  const [completeFocusedItems, setCompleteFocusedItems] = useState<
    FocusedItem[]
  >([]);

  const [isDragging, setIsDragging] = useState(false);
  const [width, setWidth] = useState(550);
  const [scheduleModal, setScheduleModal] = useState<boolean>(false);

  const [scheduleLoading, setScheduleLoading] = useState<boolean>(true);

  const [dragAbleId, setDragAbleId] = useState<string | null>(null);

  const [percentages, setPercentages] = useState({
    doneHours: 0,
    prioritizedHours: 0,
    availableHours: 0,
  });

  const [scheduleData, setScheduleData] = useState<any>([]);

  useEffect(() => {
    if (week.startDate) {
      dispatch(listFocusedPrioritise());
      dispatch(listCompleteFocusedPrioritise());
    }
  }, [dispatch, week]);

  useEffect(() => {
    setFocusedItems(originalFocusedItems);
  }, [originalFocusedItems]);

  useEffect(() => {
    setCompleteFocusedItems(originalCompleteFocusedItems);
  }, [originalCompleteFocusedItems]);

  const onDragEndHandle = (result: DropResult) => {
    setDragAbleId(null);

    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const items = Array.from(focusedItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFocusedItems(items);

    const payload = {
      priority_updates: items.map((item, index) => ({
        focused_activity_id: item?.id,
        priority: index + 1,
      })),
    };

    dispatch(updateFocusedPriorities(payload));
  };

  const onDragCompleteEndHandle = (result: DropResult) => {
    setDragAbleId(null);

    if (
      !result.destination ||
      result.destination.index === result.source.index
    ) {
      return;
    }

    const items = Array.from(completeFocusedItems);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCompleteFocusedItems(items);

    const payload = {
      priority_updates: items.map((item, index) => ({
        focused_activity_id: item?.id,
        priority: index + 1,
      })),
    };

    dispatch(updateFocusedPriorities(payload));
  };

  const onDragStartHandle = (e: any) => {
    setDragAbleId(e.draggableId);
  };

  const numberOfSkeletons = 3;

  const handleMouseDown = (e: any) => {
    setIsDragging(true);
    startXRef.current = e.clientX;
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    document.removeEventListener("mousemove", handleMouseMove);
    document.removeEventListener("mouseup", handleMouseUp);
  };

  const handleMouseMove = (e: any) => {
    const deltaX = e.clientX - startXRef.current;
    const newWidth = width - deltaX;

    const maxSidebarWidth = window.innerWidth;

    if (newWidth >= 455 && newWidth <= maxSidebarWidth) {
      setWidth(newWidth);
    }
  };

  useEffect(() => {
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  const handleSchedule = () => {
    setScheduleModal(true);
    setScheduleLoading(true);
    handleScheduleAll();
  };

  const handleScheduleAll = () => {
    const getEndpoint = () => {
      const endpoint = "schedule_weekly_priorities";
      const additionalData = {
        // user_id: userDetail.id,
        user_id: goalSettingWorkbookKey?.goal_setting_workbook,
        start_date_of_week: week.startDate,
        end_date_of_week: week.endDate,
      };

      return {
        endpoint,
        data: additionalData,
      };
    };

    websocketConnection.send(JSON.stringify(getEndpoint()));
  };

  useEffect(() => {
    websocketConnection.connect();
    websocketConnection.setMessageCallback(handleMessage);

    return () => {
      websocketConnection.close();
    };
  }, []);

  const handleMessage = (message: string) => {
    const res = JSON.parse(message);
    if (res?.message) {
      setScheduleLoading(false);
      setScheduleData(res?.message);
    }
  };

  useEffect(() => {
    if (isDragging) {
      document.body.classList.add("dragging");
    } else {
      document.body.classList.remove("dragging");
    }
  }, [isDragging]);

  const isFocused = focusedItems && focusedItems?.length > 0;
  const isCompleteFocused =
    completeFocusedItems && completeFocusedItems?.length > 0;

  return (
    <>
      <ScheduleModel
        open={scheduleModal}
        onClose={() => setScheduleModal(false)}
        startWeekDate={week.startDate}
        endWeekDate={week.endDate}
        scheduleData={scheduleData}
        scheduleLoading={scheduleLoading}
      />

      <Wrapper
        ref={wrapperRef}
        $mobile={screenSizes === "mobile"}
        $ipad={ipadView || false}
        $coachView={!!id}
        width={width}
        isDragging={isDragging}
      >
        <SectionDrag onMouseDown={handleMouseDown}>
          <ArrowLeft />
          <ArrowRight />
        </SectionDrag>

        {/* <Loader /> */}

        <TargetSection>
          <TargetTitle>
            <TargetButton />
            My Week’s Priorities
          </TargetTitle>

          <TargetCalanderSection>
            <TargetCalander>
              <CustomWeekPicker />
            </TargetCalander>
            <Popover
              content={<PriortizedPopover TargetPopover={true} />}
              title={null}
              trigger="hover"
              placement="topRight"
              overlayClassName="target_popover"
              overlayStyle={{ maxWidth: "200px", zIndex: 9999 }}
            >
              <CursorPointer>
                <QuestionIcn />
              </CursorPointer>
            </Popover>
            <CloseIcon>
              <IoClose
                onClick={() => {
                  setBackground(false);
                  setIpadView(false);
                }}
              />
            </CloseIcon>
          </TargetCalanderSection>
        </TargetSection>

        <ProgressSection>
          <ProgressBar setPercentages={setPercentages} />
          {(isFocused || isCompleteFocused) && (
            <ProgressActions>
              <ProgressAction>
                <Dot $color="#4AC348"></Dot>
                <div>
                  Done: <span>{percentages.doneHours}hrs</span>
                </div>
              </ProgressAction>

              <ProgressAction>
                <Dot $color="#54B1F4"></Dot>
                <div>
                  Prioritized: <span>{percentages.prioritizedHours}hrs</span>
                </div>
              </ProgressAction>

              <ProgressAction>
                <Dot $color="#DDDDDD"></Dot>
                <div>
                  Available: <span>{percentages.availableHours}hrs</span>
                </div>
              </ProgressAction>
            </ProgressActions>
          )}
        </ProgressSection>

        {isFocused && (
          <ScheduleSection>
            <ScheduleTitle onClick={handleSchedule}>
              <ScheduleGray />
              My Schedule
            </ScheduleTitle>

            {/* <ScheduleButton onClick={handleScheduleAll}>
            <Stars />
            Schedule My Week
          </ScheduleButton> */}
          </ScheduleSection>
        )}

        <ScheduleListContainer>
          <RelativeWrapper>
            {isFocused && (
              <HiddenBox>
                {[
                  ...Array(
                    focusedItems?.length > 2
                      ? focusedItems?.length
                      : numberOfSkeletons
                  ),
                ].map((_, index) => (
                  <BackgroundSkeleton key={index} />
                ))}
              </HiddenBox>
            )}

            {isFocused ? (
              <DragDropContext
                onDragEnd={onDragEndHandle}
                onDragStart={onDragStartHandle}
              >
                <Droppable droppableId="droppable" type="group">
                  {(provided: any) => (
                    <div {...provided.droppableProps} ref={provided.innerRef}>
                      {focusedItems.map((ele: any, index) => (
                        <List
                          key={ele.id}
                          data={ele}
                          index={index}
                          onFocusToggle={onFocusToggle}
                          dragAbleId={dragAbleId}
                        />
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            ) : !isCompleteFocused ? (
              <EmptyCard numberOfSkeletons={7} />
            ) : (
              <EmptyCard numberOfSkeletons={numberOfSkeletons} />
            )}
          </RelativeWrapper>

          {isCompleteFocused && (
            <>
              <CompletedHeading>Completed Activities</CompletedHeading>

              <RelativeWrapper>
                {isCompleteFocused && (
                  <HiddenBox>
                    {[...Array(numberOfSkeletons)].map((_, index) => (
                      <BackgroundSkeleton key={index} />
                    ))}
                  </HiddenBox>
                )}

                {isCompleteFocused ? (
                  <DragDropContext
                    onDragEnd={onDragCompleteEndHandle}
                    onDragStart={onDragStartHandle}
                  >
                    <Droppable droppableId="droppable" type="group">
                      {(provided: any) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {completeFocusedItems?.map(
                            (ele: any, index: number) => (
                              <List
                                key={ele.id}
                                data={ele}
                                index={index}
                                onFocusToggle={onFocusToggle}
                                dragAbleId={dragAbleId}
                                completed
                              />
                            )
                          )}
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                  </DragDropContext>
                ) : (
                  <EmptyCard numberOfSkeletons={numberOfSkeletons} />
                )}
              </RelativeWrapper>
            </>
          )}
        </ScheduleListContainer>

        <FooterText>Drag to reorder your focused priorities</FooterText>
      </Wrapper>
    </>
  );
};

export default FocusedPriorities;
