import styled from "styled-components";

interface ValueProps {
  $error: boolean;
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  margin-top: 14px;
  margin-bottom: 21px;
}`;

export const Label = styled.span`
  color: var(--Neutral-700, #404040);
  text-align: center;
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
}`;

export const Value = styled.span<ValueProps>`
  padding: 6px 10px;
  border-radius: 2px;
  color: var(--Neutral-700, #404040);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  border: 1px solid #CDCDCD;
  background: var(--Dark-mode-bg, #FFF);
  box-shadow: 0px 0px 0px 3px rgba(42, 159, 54, 0.25);
  border: 1px solid #59A310;

  ${({ $error }) => $error && `
    border-color: #EF6C23;
    box-shadow: 0px 0px 0px 3px rgba(255, 153, 0, 0.25);
  `};
}`;
